export default {
  root: {
    scrollBehavior: 'smooth',
    fontFamily: 'regular',
    fontSize: '14px',
    backgroundColor: 'background',
    color: '#fff',
    a: {
      textDecoration: 'none',
      color: 'primary',
    },
  },
}
