export const CONTACT = 'contact'
export const ENTRY = 'entry'
export const SURVEY = 'survey'
export const CLOSED = 'closed'
export const SUBMISSION = 'submission'
export const BYDB_BASE_PATH = 'buildyourdreambag'
export const GREATEST_HITS_BASE_PATH = 'greatesthits'
export const ULTIMATE_COACHING_TRIP_BASE_PATH = 'ultimate_coaching_trip'
export const UCT_PREFIX = 'taylormade_ultimate_coaching_trip_2025'

const PAGES = {
  EXTERNAL_ENDPOINTS: {
    GEOIP: `${process.env.GEO_IP_API_NAME_URL || 'http://geo-ip.green.room'}/api/v1/country`,
  },

  ENDPOINTS: {
    ROOT: '/',
    CONTACT: '/contact',
    SURVEY: '/survey',
    GEOIP: '/geoip',
    GENERATE_IMAGE: {
      ROOT: '/generate-image',
      BYDB: {
        DREAM_BAG: '/generate-image/bydb/dream-bag',
        SUBMISSION: '/generate-image/bydb/submission',
      },
    },
  },

  CLIENT: {
    ROOT: '/',
    LANDING: '/landing',
  },
}

export default PAGES
