import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import metaBYDB from '@/constants/contents/bydb/layout/meta.json'
import metaGreatestHits from '@/constants/contents/greatest_hits/layout/meta.json'
import metaUltimateCoachingTrip from '@/constants/contents/ultimate_coaching_trip/layout/meta.json'

import 'setimmediate'
import { ThemeUIProvider } from 'theme-ui'
import {
  buildYourDreamBagTheme,
  greatestHitsTheme,
  ultimateCoachingTripTheme,
} from '../styles'

import GlobalComponent from '../styles/global'
import 'react-datepicker/dist/react-datepicker.css'
import Utils from '../utils'
import { BYDB_BASE_PATH, GREATEST_HITS_BASE_PATH } from '@/constants/pages'
import { AppProviders } from '@/contexts'
import { IsSsrMobileContext } from '@/contexts/isMobile'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

if (!global.setImmediate) {
  global.setImmediate = setTimeout
}

function GreenroomApp({ Component, ...props }) {
  const router = useRouter()
  // TODO: improve this logic to make it escalable
  const appName = router.asPath.includes(BYDB_BASE_PATH)
    ? 'bydb'
    : router.asPath.includes(GREATEST_HITS_BASE_PATH)
      ? 'greatesthits'
      : 'ultimatecoahingtrip'

  let theme
  let meta

  switch (appName) {
    case 'bydb':
      theme = buildYourDreamBagTheme
      meta = metaBYDB
      break
    case 'greatesthits':
      theme = greatestHitsTheme
      meta = metaGreatestHits
      break
    default:
      theme = ultimateCoachingTripTheme
      meta = metaUltimateCoachingTrip
      break
  }

  const query = {
    locale: Utils.parseUrl(router.asPath).locale,
  }
  const locale = query.locale || 'en'

  return (
    <>
      <Head>
        <title>{meta[locale]?.title}</title>
        <link rel="shortcut icon" href={meta[locale]?.favicon} />
        {/* Any resource that should be retrieved from a CDN should be preloaded here */}
        {/* Also, any 3rd party resource should be preconnected/prefetched here */}
        <link
          rel="preload"
          href="/static/assets/fonts/Roboto-Regular.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/static/assets/fonts/Roboto-Light.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/static/assets/fonts/Roboto-Bold.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Head>
      <AppProviders>
        <ThemeUIProvider theme={theme}>
          <GlobalComponent />
          <IsSsrMobileContext.Provider value={props?.pageProps?.isSsrMobile}>
            <Component {...props} />
          </IsSsrMobileContext.Provider>
        </ThemeUIProvider>
      </AppProviders>
    </>
  )
}

export default GreenroomApp
