export const buildYourDreamBagText = {
  largeBold: {
    fontFamily: 'bold',
    fontSize: '25px',
    lineHeight: '29.3px',
  },
  medium: {
    fontFamily: 'regular',
    fontSize: ['18px', '20px'],
    lineHeight: ['21.09px', '23.44px'],
  },
  mediumBold: {
    fontFamily: 'bold',
    fontSize: ['18px', '20px'],
    lineHeight: ['21.09px', '23.44px'],
  },
  small: {
    fontFamily: 'regular',
    fontSize: ['16px', '18px'],
    lineHeight: ['18.75px', '21.09px'],
  },
  smallBold: {
    fontFamily: 'bold',
    fontSize: ['16px', '18px'],
    lineHeight: ['18.75px', '21.09px'],
  },
  label: {
    fontFamily: 'regular',
    fontSize: '10px',
    lineHeight: '11.72px',
  },
}

export const greatesHitsText = {
  title: {
    fontFamily: 'bold',
    fontSize: '25px',
    lineHeight: '29.3px',
    color: 'primary',
  },
  large: {
    fontFamily: 'regular',
    fontSize: '20px',
    lineHeight: '24px',
    color: 'textDark',
  },
  largeBold: {
    fontFamily: 'bold',
    fontSize: '20px',
    lineHeight: '24px',
    color: 'textDark',
  },
  medium: {
    fontFamily: 'regular',
    fontSize: ['16px', '18px'],
    lineHeight: ['18.75px', '30.1px'],
    color: 'textDark',
  },
  mediumBoldLight: {
    fontFamily: 'bold',
    fontSize: ['16px', '18px'],
    lineHeight: ['18.75px', '30.1px'],
    color: 'textLight',
  },
  small: {
    fontFamily: 'regular',
    fontSize: '16px',
    lineHeight: ['19.2px', '19.74px'],
    color: 'textDark',
  },
  smallBoldLight: {
    fontFamily: 'bold',
    fontSize: '16px',
    lineHeight: ['19.2px', '19.74px'],
    color: 'textLight',
  },
}

export const ultimateCoachingTripText = {
  logoTitle: {
    fontFamily: 'bold',
    fontSize: '25px',
    textTransform: 'uppercase',
    color: 'textLight',
  },
  title: {
    fontFamily: 'bold',
    fontSize: '25px',
    lineHeight: '32.81px',
    color: 'primary',
  },
  medium: {
    fontFamily: 'regular',
    fontSize: '16px',
    lineHeight: '18.75px',
    color: 'textLight',
  },
  smallBold: {
    fontFamily: 'bold',
    fontSize: '16px',
    color: 'textLight',
  },
  small: {
    fontFamily: 'light',
    fontSize: '16px',
    color: 'textLight',
  },
  mediumBold: {
    fontFamily: 'bold',
    fontSize: '20px',
  },
}
